h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Mongoose", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    clear: both;
    font-weight: bold;
}

.checkBox{
    display: flex;
    flex-direction: row-reverse;
    width: 300px;
    justify-content: flex-end;
}

.checkBox input{
    width: 20px;
    height: 25px;
    margin-right: 5px;
}

.currentFileLabel{
    color: #44337a;
}

.productModal{
    width: 25%;
}

.editProductModal{
    width: 25%;
}

.addBtn{
    font-weight: 100;
}

.editBtn{
    background-image: url(../static/edit.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    vertical-align: middle
}

.deleteBtn{
    background-image: url(../static/trash.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    vertical-align: middle
}
header {
    nav {
        .login-button {
            &::after {
                content: "";
                background: url(./static/ribbon-ending-green.svg);
                display: inline-block;
                position: absolute;
                height: 60px;
                width: 10px;
                right: -10px;
                background-position: 0;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

.min-heigth-80vh {
    min-height: 70vh;
}
